<template>
  <div class="contact-container">
    <div class="banner" :style ="banner">
      <div class="min-size">
        <div class="router-title">联系我们</div>
        <div class="router-title-en">CONTACT US</div>
      </div>
    </div>

    <div class="content">
      <div class="min-size">
        <div class="left">
          <div class="info-row text">
            <div class="info-row-icon">
              <svg-icon class="icon-address" icon-class="address" />
            </div>
            <div class="info-row-content">
              <div class="name">公司地址</div>
              <div class="value">湖南省长沙市高新区麓天路15号银河科技园3楼301室</div>
            </div>
          </div>
          <div class="info-row text">
            <div class="info-row-icon">
              <svg-icon class="icon-mail" icon-class="mail" />
            </div>
            <div class="info-row-content">
              <div class="name">电子邮箱</div>
              <div class="value">HN_yinfang@163.com</div>
            </div>
          </div>
          <div class="info-row text">
            <div class="info-row-icon">
              <svg-icon class="icon-phone" icon-class="phone" />
            </div>
            <div class="info-row-content">
              <div class="name">联系电话</div>
              <div class="value">0731-85115328 （工作日9:00~17:30）</div>
            </div>
          </div>
          <!-- <div class="info-row">
            <div class="info-row-icon">
              <svg-icon class="icon-wechat" icon-class="wechat" />
            </div>
            <div class="info-row-content wechat-qr">
              <img src="@/assets/yf/qr-code.png" class="qr" alt="qr">
            </div>
          </div> -->
        </div>
        <div class="right">
          <img src="@/assets/yf/address-map.png" class="map" alt="map">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  components: {
  },
  data () {
    return {
      banner: {
        backgroundImage: 'url(' + require('@/assets/yf/contact-bg.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center center'
      }
    }
  }
}
</script>

<style lang="less">
.contact-container {
  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 470px;

    .min-size {
      width: 1200px;
      height: 140px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .router-title {
        font-size: 48px;
        color: #ffffff;
      }

      .router-title-en {
        font-size: 48px;
        color: rgba(255,255,255,0.3);
        font-weight: 300;
      }
    }
  }

  .content {
    height: 603px;
    display: flex;
    justify-content: center;
    align-items: center;

    .min-size {
      width: 1200px;
      display: flex;
      justify-content: space-between;

      .left {
        .info-row {
          display: flex;

          &.text {
            height: 59px;

          }

          .info-row-icon {
            background: #c00000;
            border-radius: 50%;
            width: 55px;
            height: 55px;
            display: flex;
            align-items: center;
            justify-content: center;

            .svg-icon{
              font-size: 24px;
            }
          }

          .info-row-content {
            height: 100%;
            padding-left: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .name {
              font-size: 18px;
              color: #000000;
            }

            .value {
              padding-top: 14px;
              font-size: 14px;
              color: #999999;
            }
          }

          &:not(:first-child) {
            padding-top: 45px;
          }

          .wechat-qr {
            width: 90px;
            height: 90px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .right {
        width: 749px;
        height: 403px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
